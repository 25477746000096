<script setup lang="ts">
import type { SearchResponse } from "@algolia/client-search";
import type { AlgoliaProduct, AlgoliaQuerySuggestion, AlgoliaPickupLocation } from "~/types/kippie";
import { transformUrl } from "@/helpers/link-transformers";
import { getRoute } from "~/lib/routes";
import { getPickupLocationAddress } from "@/helpers/pickupLocation";

const { isOpen, close } = useSearch();
const { client } = useAlgoliaSearch();

const isLoading = ref(false);
const productSearchResults = ref<SearchResponse<AlgoliaProduct> | undefined>();
const querySuggestionResults = ref<SearchResponse<AlgoliaQuerySuggestion> | undefined>();
const pickupLocationSearchResults = ref<SearchResponse<AlgoliaPickupLocation> | undefined>();
const activeIndex = ref(-1);

const requestsWithoutQuery = [
	{
		indexName: "products",
		hitsPerPage: 4
	},
	{
		indexName: "products_query_suggestions",
		hitsPerPage: 5
	},
	{
		indexName: "pickup_locations",
		hitsPerPage: 2
	}
];

const onSearch = async (query: string) => {
	isLoading.value = true;

	activeIndex.value = query ? 0 : -1;

	const requests = requestsWithoutQuery.map((index) => ({ ...index, query }));

	const { results } = await client.search<AlgoliaQuerySuggestion | AlgoliaProduct | AlgoliaPickupLocation>({
		requests
	});

	productSearchResults.value = results.find(
		(response) => "index" in response && response.index === "products"
	) as SearchResponse<AlgoliaProduct>;

	querySuggestionResults.value = results.find(
		(response) => "index" in response && response.index === "products_query_suggestions"
	) as SearchResponse<AlgoliaQuerySuggestion>;

	pickupLocationSearchResults.value = results.find(
		(response) => "index" in response && response.index === "pickup_locations"
	) as SearchResponse<AlgoliaPickupLocation>;

	if (query) {
		querySuggestionResults.value.hits.splice(0, 0, {
			objectID: query,
			query,
			_highlightResult: {
				query: {
					value: `Zoeken op '${query}'`,
					matchedWords: [],
					matchLevel: "none"
				}
			}
		});
	}

	isLoading.value = false;
};

onKeyStroke("ArrowDown", (event) => {
	event.preventDefault();

	if (!querySuggestionResults.value?.hits.length) return;

	if (activeIndex.value < querySuggestionResults.value?.hits.length - 1) {
		activeIndex.value++;
	}
});

onKeyStroke("ArrowUp", (event) => {
	event.preventDefault();

	if (activeIndex.value > 0) {
		activeIndex.value--;
	}
});

const onSubmit = (query: string) => {
	if (activeIndex.value >= 0) {
		const suggestion = querySuggestionResults.value?.hits[activeIndex.value];
		if (suggestion) {
			close();
			return navigateTo(getRoute("search", suggestion.query));
		}
	}

	if (!query) return;

	close();
	navigateTo(getRoute("search", query));
};

whenever(isOpen, () => {
	onSearch("");
});
</script>

<template>
	<Teleport to="body">
		<KippieFullScreenSearch
			:live="true"
			v-model="isOpen"
			placeholder="Start met zoeken"
			@search="onSearch"
			@submit="onSubmit"
		>
			<div class="flex flex-col gap-4">
				<div class="flex flex-col gap-4">
					<Typography variant="h6">Vaak gezocht</Typography>

					<div class="flex flex-col gap-1">
						<NuxtLink
							class="px-2 py-1 -mx-2 transition-colors rounded-md suggestion hover:bg-gray-200"
							v-for="(suggestion, index) in querySuggestionResults?.hits"
							:key="suggestion._highlightResult?.query?.value"
							v-html="suggestion._highlightResult?.query?.value"
							:to="getRoute('search', suggestion.query)"
							:class="{
								'bg-gray-100 ': activeIndex === index
							}"
							@click="close"
						/>
					</div>
				</div>

				<div
					class="flex flex-col gap-4"
					:class="
						pickupLocationSearchResults?.nbHits && pickupLocationSearchResults.nbHits <= 2 ? 'order-2' : ''
					"
				>
					<Divider />

					<Typography variant="h6">Producten</Typography>
					<span class="text-sm text-gray" v-if="!productSearchResults?.hits.length">Geen zoekresultaten</span>
					<div class="grid grid-cols-1 gap-4 mx-0 lg:grid-cols-2 md:gap-4">
						<NuxtLink
							v-for="product in productSearchResults?.hits"
							:key="product.objectID"
							:to="transformUrl(product.seoUrl || `detail/${product.objectID}`)"
							class="group"
						>
							<KippieSearchResultCard
								@click="close"
								class="flex flex-row h-full max-w-full border border-brown"
							>
								<template #image v-if="!!product.image">
									<NuxtImg
										format="webp"
										class=""
										:src="product.image.url || 'https://placehold.co/600x400'"
										:provider="product.image.provider"
										:alt="product.name"
										height="200"
										quality="100"
										placeholder
									/>
								</template>
								<template #labels>
									<KippieLabel
										v-for="label in product.labels.filter((label) => label)"
										:key="`${product.objectID}_${label}`"
										:label="label"
										:border="true"
										class="truncate"
									/>
								</template>
								<template v-if="!!product.mainLabel" #action>
									<p class="px-2 py-1 text-white truncate rounded-l-full w-fit text-2xs bg-red">
										{{ product.mainLabel }}
									</p>
								</template>

								{{ product.name }}
							</KippieSearchResultCard>
						</NuxtLink>
					</div>
				</div>

				<div
					class="flex flex-col gap-4"
					v-if="
						pickupLocationSearchResults?.nbHits &&
						pickupLocationSearchResults.nbHits <= 5 &&
						pickupLocationSearchResults.nbHits >= 1
					"
					:class="pickupLocationSearchResults.nbHits <= 2 ? 'order-1' : ''"
				>
					<Divider />

					<Typography variant="h6">Winkels</Typography>

					<div class="grid grid-cols-1 gap-4 mx-0 lg:grid-cols-2">
						<NuxtLink
							v-for="store in pickupLocationSearchResults?.hits"
							:key="store.objectID"
							:to="transformUrl(store.slug)"
							class="group"
						>
							<KippieSearchResultCard
								class="h-full border border-brown"
								:product="false"
								@click="close"
								hide-favourite
							>
								<template v-if="!!store.name" #action>
									<p class="text-white rounded-r-full w-fit text-2xs bg-red">
										{{ store.name }}
									</p>
								</template>
								<div class="flex flex-col grow">
									<p class="text-sm font-bold">
										{{ store.name }}
									</p>
									<p class="text-xs">
										<span>
											{{ getPickupLocationAddress(store.address)?.address + ", " }}
										</span>
										<span>
											{{ getPickupLocationAddress(store.address)?.city }}
										</span>
									</p>
								</div>
							</KippieSearchResultCard>
						</NuxtLink>
					</div>
				</div>
			</div>
		</KippieFullScreenSearch>
	</Teleport>
</template>

<style scoped>
.suggestion :deep(em) {
	font-weight: 700;
}
</style>
